const data = {
  title: 'Patricio González',
  keywords: [
    'developer',
    'backend',
    'frontend',
    'fullstack',
    'sofware',
    'programming',
    'code',
    'desarrollo',
    'programación',
  ],
  description: 'sitio personal de Patricio Gonzalez',
};

export default data;
